<template>
  <div class="flex flex-col pt-7 font-Inter">
    <div class="flex flex-col bg-white shadow px-6 pt-4 mb-6 customer-white">
      <div class="flex flex-row mb-4 items-center justify-end">
        <router-link class="mr-2" to="/stock/adjustment/create">
          <el-button type="primary" icon="el-icon-plus">Créer</el-button>
        </router-link>
        <el-select
          class="mr-2"
          v-model="listQuery.warehouseFrom"
          placeholder="Entrepôt"
          clearable
          filterable
          @clear="handleClearWarehouseCode"
          @change="fetchData()"
        >
          <el-option
            v-for="(item, index) in warehouses"
            :key="index"
            :value="item.code"
            :label="item.name"
          >
          </el-option>
        </el-select>

        <el-date-picker
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button @click="fetchData()" class="mr-2">Recherche</el-button>
        <el-button
          icon="el-icon-refresh-right"
          class=""
          @click="handleRefreshFilter"
        ></el-button>
      </div>
      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%">
          <el-table-column prop="date" label="DATE création" width="180">
            <template slot-scope="{ row }">
              <span>{{ row.date }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="numero" label="NUMERO" width="150">
            <template slot-scope="{ row }">
              <span class="text-blue">{{ row.number }}</span>
            </template>
          </el-table-column>
          <el-table-column label="STATUT" width="150" align="left">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>
          <el-table-column width="215" label="RAISON">
            <template slot-scope="{ row }">
              <span>{{ row.raison }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150" label="NOTE">
            <template slot-scope="{ row }">
              <span>{{ row.note }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="count_items"
            align="center"
            label="ARTICLE"
            width="160"
          >
            <template slot-scope="{ row }">
              <span> {{ row.total_item }} </span>
            </template>
          </el-table-column>

          <el-table-column width="180" align="right">
            <template slot-scope="scope">
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Voir les articles"
                placement="bottom-end"
              >
                <el-button
                  circle
                  size="mini"
                  icon="el-icon-files"
                  @click="handleDialog(scope.row.items)"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="Modifier l'article"
                placement="bottom-end"
              >
                <el-button
                  v-if="scope.row.status !== 'CANCELED'"
                  @click="handleCancelTransfer(scope.row)"
                  circle
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchTransferStock()"
        />
      </div>
    </div>

    <el-dialog
      title="LA LISTE DES ARTICLES "
      :visible.sync="dialogVisible"
      width="60%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <div class="dialog_content_height">
        <div class="article-items">
          <el-table border :data="adjustemItems" style="width: 100%">
            <el-table-column align="center" label="C.E" width="50">
              <template slot-scope="{ row }">
                <span class="text-blue-500"> {{ row.warehouse_code }}</span>
              </template>
            </el-table-column>
            <el-table-column label="N°" width="100">
              <template slot-scope="{ row }">
                <span class="text-blue-500"> {{ row.article_sku }}</span>
              </template>
            </el-table-column>
            <el-table-column label="DESIGNATION " min-width="180">
              <template slot-scope="{ row }">
                <span> {{ row.article_name }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="quantity_in"
              label="QUANTITÉ AVANT"
              width="140"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>
                  {{ row.quantity_before }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity_in"
              label="QUANTITÉ AJUSTÉE"
              width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>
                  {{ row.quantity_adjusted }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity_in"
              label="NOUVELLE QUANTITÉ"
              width="160"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>
                  {{ row.quantity_after }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { parseTime, parseDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import {
  fetchAdjustmentStock,
  fetchWarehouse,
  cancelAdjustmentStock,
} from "@/api/stock";

import { exportTransferStock } from "@/api/export";

import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "AJUSTEMENT-STOCK",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseTime,
    filterDate: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      items: [],
      articleItems: [],
      warehouses: [],
      adjustemItems: [],
      dialogVisible: false,
      listLoading: false,
      loadingCancel: false,
      selectedIndex: undefined,
      dowloandLoading: false,
      selecedExporIndex: undefined,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        warehouseCode: undefined,
        sortDir: "createdAt",
      },
    };
  },
  mounted() {
    this.fetchWarehouse();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      await fetchAdjustmentStock(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
            console.log(this.items);
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleDialog(items) {
      this.adjustemItems = items;
      this.dialogVisible = true;
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },
    handleClearWarehouseCode() {
      this.listQuery.warehouseCode = undefined;
      this.fetchData();
    },

    handleRefreshFilter() {
      this.listQuery = {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        warehouseCode: undefined,
        raison: undefined,
        sortDir: "createdAt",
      };
      this.fetchData();
    },
    handleCancelTransfer(row) {
      //
      const h = this.$createElement;
      this.$msgbox({
        title: "Ajustement N° " + row.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler l'ajustement  "),
          h("span", { style: "font-weight:bold" }, "N°:" + row.number),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelAdjustmentStock(row.number)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  row.status = "CANCELED";
                  this.$message({
                    type: "success",
                    message: "La facture a été annulée",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    exportTransferDoc(code, index) {
      this.selecedExporIndex = index;
      this.dowloandLoading = true;
      exportTransferStock(code)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute(
              "download",
              "transfer_de_stocke_no_" + code + ".pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.dowloandLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
