<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 text-2xl font-semibold py-2">
        Gestion de stocks
      </div>
    </PageHeader>

    <div class="flex flex-col">
      <div class="bg-white h-12 pt-2 px-10 pb-0">
        <el-tabs v-model="activeName">
          <!-- <el-tab-pane label="ARTICLES" name="ARTICLE"> </el-tab-pane> -->
          <el-tab-pane label="MOUVEMENTS" name="MOVEMENT"> </el-tab-pane>
          <el-tab-pane name="BON-SORTIE" label="BONS DE SORTIE"> </el-tab-pane>
          <el-tab-pane name="BON-ENTREE" label="BONS D'ENTRÉE"> </el-tab-pane>
          <el-tab-pane name="AJUSTMENT-STOCK" label="AJUSTMENTS DES STOCKS">
          </el-tab-pane>
          <el-tab-pane name="TRANSFER-STOCK" label="TRANSFERT DES STOCKS">
          </el-tab-pane>
          <el-tab-pane name="INVENTORY" label="INVENTAIRES"> </el-tab-pane>
        </el-tabs>
      </div>
      <keep-alive>
        <div class="px-10">
          <!--   <Article v-if="activeName === 'ARTICLE'" class="px-6" /> -->
          <movement v-if="activeName === 'MOVEMENT'" />
          <bon-sortie v-if="activeName === 'BON-SORTIE'" />
          <bon-entree v-if="activeName === 'BON-ENTREE'" />
          <inventory v-if="activeName === 'INVENTORY'" />
          <transfer-stock v-if="activeName === 'TRANSFER-STOCK'" />
          <adjustment v-if="activeName === 'AJUSTMENT-STOCK'" />
        </div>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import BonEntree from "./EntryVoucher";
import BonSortie from "./OutingVoucher";
import Inventory from "./Inventory";
import Movement from "./Movement";
import Adjustment from "./Adjutment";
// import Article from "./Article";
import TransferStock from "./Transfer";

export default {
  components: {
    PageHeader,
    BonEntree,
    BonSortie,
    Movement,
    Inventory,
    /*   Article, */
    TransferStock,
    Adjustment,
  },
  data() {
    return {
      activeName: "MOVEMENT",
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    // init the default selected tab
    const tab = this.$route.query.tab;
    if (tab) {
      this.activeName = tab;
    }
  },
};
</script>

<style lang="scss" scoped></style>
