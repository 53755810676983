<template>
  <div class="flex flex-col font-Inter">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          ></h2>
          <div class="flex items-center text-sm text-gray-400"></div>
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-white border px-6 pt-4 mb-6 customer-white">
      <div class="flex flex-row mb-4 items-center justify-end">
        <router-link class="mr-2" to="/stock/transfer/create">
          <el-button type="primary" icon="el-icon-plus"
            >Créer un transfert</el-button
          >
        </router-link>
        <el-select
          class="mr-2"
          v-model="listQuery.warehouseFrom"
          placeholder="Entrepôt source"
          clearable
          filterable
          @clear="handleClearWarehouseFrom"
          @change="fetchTransferStock()"
        >
          <el-option
            v-for="(item, index) in warehouses"
            :key="index"
            :value="item.code"
            :label="item.name"
          >
          </el-option>
        </el-select>
        <el-select
          class="mr-2"
          v-model="listQuery.warehouseTo"
          placeholder="Entrepôt destination"
          clearable
          filterable
          @clear="handleClearWarehouseTo"
          @change="fetchTransferStock()"
        >
          <el-option
            v-for="(item, index) in warehouses"
            :key="index"
            :value="item.code"
            :label="item.name"
          >
          </el-option>
        </el-select>
        <el-date-picker
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button @click="fetchTransferStock()" class="mr-2"
          >Recherche</el-button
        >
        <el-button
          icon="el-icon-refresh-right"
          class=""
          @click="handleRefreshFilter"
        ></el-button>
      </div>
      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%">
          <el-table-column prop="date" label="DATE" width="180">
            <template slot-scope="{ row }">
              <span>{{ row.date | filterTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="FAITE PAR">
            <template slot-scope="{ row }">
              <span class="text-blue-500 cursor-pointer">{{ row.user }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="numero" label="NUMERO" width="150">
            <template slot-scope="{ row }">
              <span>{{ row.number }}</span>
            </template>
          </el-table-column>
          <el-table-column label="STATUT" width="140" align="center">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>
          <el-table-column prop="type" label="ENTREPÔT SOURCE">
            <template slot-scope="{ row }">
              <span>{{ row.warehouse_name_from }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="ENTREPÔT DESTINATION">
            <template slot-scope="{ row }">
              <span>{{ row.warehouse_name_to }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="count_items"
            align="right"
            label="NBRE D'ARTICLE"
            width="160"
          >
            <template slot-scope="{ row }">
              <span> {{ row.total_item }} </span>
            </template>
          </el-table-column>

          <el-table-column width="180" align="right">
            <template slot-scope="scope">
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Imprimer "
                placement="bottom-end"
              >
                <el-button
                  circle
                  size="mini"
                  icon="el-icon-printer"
                  @click="exportTransferDoc(scope.row.number, scope.$index)"
                  :loading="
                    scope.$index === selecedExporIndex ? dowloandLoading : false
                  "
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item mr-2"
                effect="dark"
                content="Voir les articles"
                placement="bottom-end"
              >
                <el-button
                  circle
                  size="mini"
                  icon="el-icon-files"
                  @click="handleDialog(scope.row.line_items)"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="Modifier l'article"
                placement="bottom-end"
              >
                <el-button
                  v-if="scope.row.status !== 'CANCELED'"
                  @click="handleCancelTransfer(scope.row, scope.$index)"
                  :loading="
                    scope.$index === selectedIndex ? loadingCancel : false
                  "
                  circle
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchTransferStock()"
        />
      </div>
    </div>

    <el-dialog
      title="LISTE DES ARTICLES TRANSFERES"
      :visible.sync="dialogVisible"
      width="45%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <div class="list-article">
        <el-table :data="articleItems" style="width: 100%">
          <el-table-column label="SKU" width="150">
            <template slot-scope="{ row }">
              <span class="text-blue-500"> {{ row.article_sku }}</span>
            </template>
          </el-table-column>
          <el-table-column label="DESIGNATION DE L'ARTICLE" min-width="180">
            <template slot-scope="{ row }">
              <span> {{ row.article_name }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="quantity_in"
            label="QUANTITE TRANSFEREE"
            width="200"
            align="right"
          >
            <template slot-scope="{ row }">
              <div>
                {{ row.quantity_transfer }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { parseTime, parseDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import {
  fetchTransferStock,
  fetchWarehouse,
  cancelTransferStock,
} from "@/api/stock";

import { exportTransferStock } from "@/api/export";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "BonSortie",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseTime,
    filterDate: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      items: [],
      articleItems: [],
      warehouses: [],
      dialogVisible: false,
      listLoading: false,
      loadingCancel: false,
      selectedIndex: undefined,
      dowloandLoading: false,
      selecedExporIndex: undefined,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        warehouseFrom: undefined,
        warehouseTo: undefined,
        numero: undefined,
        number: undefined,
        sortDir: "createdAt",
      },
    };
  },
  mounted() {
    this.fetchWarehouse();
    this.fetchTransferStock();
  },
  methods: {
    async fetchTransferStock() {
      this.listLoading = true;
      await fetchTransferStock(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
            console.log(this.items);
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleDialog(items) {
      this.articleItems = items;
      this.dialogVisible = true;
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },
    handleClearWarehouseFrom() {
      this.listQuery.warehouseFrom = undefined;
      this.fetchTransferStock();
    },
    handleClearWarehouseTo() {
      this.listQuery.warehouseTo = undefined;
      this.fetchTransferStock();
    },
    handleRefreshFilter() {
      this.listQuery = {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        warehouseFrom: undefined,
        warehouseTo: undefined,
        numero: undefined,
        number: undefined,
        sortDir: "createdAt",
      };
      this.fetchTransferStock();
    },
    async handleCancelTransfer(row, index) {
      this.selectedIndex = index;
      this.loadingCancel = true;
      await cancelTransferStock(row.number)
        .then(() => {
          setTimeout(() => {
            row.status = "CANCELED";
            this.loadingCancel = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportTransferDoc(code, index) {
      this.selecedExporIndex = index;
      this.dowloandLoading = true;
      exportTransferStock(code)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute(
              "download",
              "transfer_de_stocke_no_" + code + ".pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.dowloandLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
